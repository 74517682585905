.has-dropdown a::after {
  border-color: hsl(348, 100%, 61%) !important;
}

.Menu-item-link {
  text-decoration: underline;
}

.Menu-item-link:hover {
  background-color: hsl(48, 100%, 96%);
}
